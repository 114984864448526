import React, { lazy, Suspense, useMemo } from 'react';
import { PublicRoute } from 'routes/PublicRoute';
import { allRoutes, RouteType } from 'routes/AllRoutes';
import { Router, Switch } from 'react-router-dom';
import history from 'Shared/history';
import { StartPage, NotFoundPage, Verified } from 'pages/exportPages';
import 'bootstrap/dist/css/bootstrap.min.css';
import { setupResizeListener } from 'utilities/breakpoints';
import { store, RootState } from './redux-tk/store';
import styled from 'styled-components';
import { checkAuth } from 'utilities/roleUtillity';
import { useSelector } from 'react-redux';
import { allUserRoles } from 'utilities/constants';

// Lazy load secondary routes like Private and Edit routes
const PrivateRoute = lazy(() => import('routes/PrivateRoute'));
const EditRoute = lazy(() => import('routes/EditRoute'));

const App: React.FC = () => {
  setupResizeListener(store);
  const user = useSelector((state: RootState) => state.currentUser.user);

  console.log('the user', user);

  const filteredRoutes = useMemo(
    () =>
      allRoutes.filter(
        (f) =>
          (f.type === RouteType.Public && user.Id === 0) ||
          (f.type === RouteType.Private && user.Id !== 0) ||
          (f.type === RouteType.Edit && user.role === allUserRoles.Admin)
      ),
    [user]
  );

  console.log('ROUTES', filteredRoutes);

  // return (
  //   <Main role="main">
  //     <Router history={history}>
  //       <Switch>
  //         {filteredRoutes.map((prop) =>
  //           prop.type === RouteType.Public && !checkAuth() ? (
  //             <Routes path={prop.path} component={prop.component} key={prop.id} />
  //           ) : prop.type === RouteType.Private ? (
  //             <PrivateRoute path={prop.path} component={prop.component} key={prop.id} />
  //           ) : (
  //             <EditRoute path={prop.path} component={prop.component} key={prop.id} />
  //           )
  //         )}
  //         <Routes path="/verified/:guidId" component={Verified} />
  //         <Routes exact path="/" component={StartPage} />

  //         <Routes component={NotFoundPage} />
  //       </Switch>
  //     </Router>
  //   </Main>
  // );

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Main role="main">
        <Router history={history}>
          <Switch>
            {filteredRoutes.map((prop) => {
              if (prop.type === RouteType.Public && !checkAuth()) {
                return <PublicRoute path={prop.path} component={prop.component} key={prop.id} />;
              }

              // For Admins: Admin should see both PrivateRoute and EditRoute
              if (user.role === allUserRoles.Admin) {
                if (prop.type === RouteType.Private || prop.type === RouteType.Edit) {
                  return <EditRoute path={prop.path} component={prop.component} key={prop.id} />;
                }
              }

              // Regular users should see PrivateRoutes
              if (prop.type === RouteType.Private) {
                return <PrivateRoute path={prop.path} component={prop.component} key={prop.id} />;
              }

              return null; // Fallback for any routes that don't match the conditions
            })}

            {/* Do not lazy load critical routes */}
            <PublicRoute path="/verified/:guidId" component={Verified} />
            <PublicRoute exact path="/" component={StartPage} />
            <PublicRoute component={NotFoundPage} />
          </Switch>
        </Router>
      </Main>
    </Suspense>
  );
};

export default App;

const Main = styled.div`
  box-shadow: inset 0 0 0 100vh rgba(0, 0, 0, 0.7);
`;
