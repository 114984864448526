import React, { Suspense, lazy } from 'react';
import { Route, RouteProps } from 'react-router';
import { RouteInfo } from './AllRoutes';

const Layout = lazy(() => import('components/Layout/Layout'));

export const PublicRoute: React.FC<RouteProps> = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Suspense fallback={<div>Loading...</div>}>
        <Layout>{Component && <Component {...props} />}</Layout>
      </Suspense>
    )}
  />
);
