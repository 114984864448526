import axios from 'axios';
import { backendUrl } from 'config/constants';
import { initalUserState, switchUser } from 'redux-tk/slices/userSlice';
import { store } from 'redux-tk/store';

const axiosInstance = axios.create({
  baseURL: backendUrl, // Set your base API URL
  withCredentials: true, // Send cookies with requests
});

export const refreshToken = async (): Promise<void> => {
  try {
    // Send a request to the refresh token endpoint
    await axiosInstance.post(
      `${backendUrl}refresh`,
      {},
      {
        withCredentials: true, // Include cookies in the request
      }
    );

    // If successful, the server should set a new access token in an HTTP-only cookie
  } catch (error) {
    console.error('Failed to refresh token', error);
    throw new Error('Token refresh failed'); // Throw an error if refresh fails
  }
};

axiosInstance.interceptors.response.use(
  (response) => response, // Pass successful responses through
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true; // Prevent infinite loops
      try {
        await refreshToken(); // Refresh the token
        return axiosInstance(originalRequest); // Retry the original request
      } catch (refreshError) {
        store.dispatch(switchUser(initalUserState.user));
        return Promise.reject(refreshError); // Handle token refresh failure
      }
    } else if (error.response.status === 401) {
      store.dispatch(switchUser(initalUserState.user));

      console.log('logout');
      return Promise.reject(error);
    } else return Promise.reject(error); // Reject other errors
  }
);

export default axiosInstance;
