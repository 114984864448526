import React from 'react';
import { StartPage, Login, Upload, AllImages, Register } from 'pages/exportPages';
import { ImageFeather, LogInFeather, UploadFeather, UserPlusFeather } from 'Shared/icon';
import styled from 'styled-components';

export enum RouteType {
  Public,
  Private,
  Edit,
}

export const routePoints = {
  start: '/start',
  person: '/person',
  login: '/login',
  upload: '/upload',
  images: '/images',
  register: '/register',
};

const UploadIcon = styled(UploadFeather)`
  font-size: 20px;
`;
const ImageIcon = styled(ImageFeather)`
  font-size: 20px;
`;
const UserIcon = styled(UserPlusFeather)`
  font-size: 20px;
`;
const LoginIcon = styled(LogInFeather)`
  font-size: 20px;
`;

export type RouteInfo = {
  id: number;
  path: string;
  component: React.FC;
  text: string;
  type: RouteType;
  icon?: React.ComponentType<React.SVGAttributes<SVGElement>>;
};

export const allRoutes: Array<RouteInfo> = [
  {
    id: 1,
    path: routePoints.start,
    component: StartPage,
    text: 'Start',
    type: RouteType.Public,
  },

  {
    id: 2,
    path: routePoints.login,
    component: Login,
    text: 'login',
    type: RouteType.Public,
    icon: LoginIcon,
  },

  {
    id: 3,
    path: routePoints.upload,
    component: Upload,
    text: 'Upload',
    type: RouteType.Edit,
    icon: UploadIcon,
  },
  {
    id: 4,
    path: routePoints.images,
    component: AllImages,
    text: 'Images',
    type: RouteType.Edit,
    icon: ImageIcon,
  },

  {
    id: 5,
    path: routePoints.register,
    component: Register,
    text: 'register',
    type: RouteType.Public,
    icon: UserIcon,
  },

  // {
  //   id: 2,
  //   path: routePoints.search,
  //   component: SearchPage,
  //   text: 'Search',
  // },

  // {
  //   id: 3,
  //   path: routePoints.gallery,
  //   component: GalleryPage,
  //   text: 'Gallery',
  // },
];
